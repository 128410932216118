<template>
  <card type="default" header-classes="bg-transparent">
    <loading :active="isLoading" :can-cancel="false" :is-full-page="false" />
    <TimeSelect :displayInfo="{ name: $t('dashboard.chart_name[5]') }" :initBigChart="initBigChart" type="order" :queryParams="queryParams" />
    <LineChart :height="350" ref="bigChart" :chart-data="chartModel.chartData" :options="chartModel.extraOptions" />
  </card>
</template>
<script>
import { lineChartOptionsBlue } from '@/components/Charts/config';
import LineChart from '@/components/Charts/LineChart';
import TimeSelect from '@/components/Charts/TimeSelect.vue';

export default {
  name: 'OrderPayments',
  components: { TimeSelect, LineChart },
  data() {
    return {
      queryParams: {},
      isLoading: false,
      chartModel: { chartData: {}, extraOptions: lineChartOptionsBlue },
      rawData: [],
      colors: ['#DB4437', '#bc32dd', '#008080', '#44e3ef', '#4267B2', '#F4B400', '#2dce89', '#FF6384'],
    }
  },
  methods: {
    async getOrderPayments() {
      this.isLoading = true
      await this.$store
        .dispatch('request/getOrderPayments', this.queryParams)
        .then(() => (this.isLoading = false))
        .catch(() => (this.isLoading = false))
      this.rawData = this.$store.getters['request/orderPayments'] ?? []
    },

    initBigChart() {
      // order line chart set
      this.getOrderPayments().then(() => {
        const keys = ['applepay', 'googlepay', 'checkout', 'barclaycard', 'paypal', 'wallet', 'total']
        const showAxcess = this.rawData.reduce((res, item) => {
          if (!res && Number(item.axcess) > 0) {
            res = true
          }
          return res
        }, false)
        if (showAxcess) {
          keys.splice(0, 0, 'axcess')
        } // else axcess has no value > 0, do not show axcess data
        const datasets = keys.map((key, index) => {
          const i = showAxcess ? index : index + 1
          return {
            label: index == keys.length - 1 ? this.$t('general.total') : this.$t('dashboard.order_payments')[i],
            data: this.rawData.map((val) => Number(val[key])),
            borderColor: this.colors[i],
            pointBackgroundColor: this.colors[i],
            tension: 0.1,
          }
        })
        this.chartModel.chartData = {
          datasets: datasets,
          labels: this.rawData.map((val) => this.$moment(val.day).format('DD/MM')),
        }
      })
    },
  },
  // queryParams will be initialized when TimeSelect created
  mounted() {
    this.initBigChart()
  },
}
</script>
