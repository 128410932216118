<template>
  <b-modal
    lazy
    centered
    id="invoice-lookup"
    :title="$t('product.invoice_lookup')"
    title-class="w-100 d-flex justify-content-center align-self-center"
    header-class="py-2"
    footer-class="py-2"
    body-class="py-0"
    dialog-class="invoice-lookup-modal"
    ok-only
    ok-variant="outline-secondary"
    ok-title="Close"
    @show="onShow"
    :busy="isLoading">
    <loading :active="isLoading" :can-cancel="false" :is-full-page="false" />
    <div class="py-2 d-flex flex-row flex-wrap align-items-start">
      <b-form-input v-model="queries.id" type="search" :placeholder="$tc('product.invoice', 1) + ' ID'" class="mr-2 mb-2" style="width: 120px" />
      <b-form-input v-model="queries.product_id" type="search" :placeholder="$t('product.product_id')" class="mr-2 mb-2" style="width: 120px" />
      <b-form-input v-model="queries.value" type="search" placeholder="Keywords" class="mr-2 mb-2" style="width: 120px" />
      <date-range-picker
        v-model="dateRange"
        :locale-data="{ format: 'yyyy-mm-dd' }"
        :auto-apply="true"
        :show-dropdowns="true"
        :ranges="ranges"
        :max-date="maxDate"
        class="mb-2 mr-2"
        style="width: max-content; min-width: 220px" />
      <button-group :onSearch="onSearch" :onReset="onReset" class="mr-2 mb-2" />
    </div>
    <b-table
      show-empty
      small
      hover
      striped
      responsive
      sticky-header="460px"
      head-variant="light"
      class="m-0 p-0"
      tbody-class="h-100"
      :fields="fields"
      :items="items"
      :busy="isLoading"
      @row-clicked="rowClicked">
      <!-- TODO: TODO: read security aws url for product invoice -->
      <template #cell(src)="{ value, item }">
        <a :href="item.src.indexOf('security') > -1 ? item.src_security : item.src" target="_blank" v-b-tooltip.hover.right="value">
          <i v-if="item.src.indexOf('.pdf') > -1" class="fa fa-file-pdf">{{ item.name }}</i>
          <b-img-lazy v-else-if="item.src.indexOf('security') < 0" :src="item.src" alt="Image" fluid block rounded style="max-height: 100px" class="hover-image" />
          <b-img-lazy v-else-if="item.src_security" :src="item.src_security" alt="Image" fluid block rounded style="max-height: 100px" class="hover-image" />
        </a>
      </template>
      <template #cell(action)="{ item }">
        <b-button @click="onSelect(item)" :variant="isSelected(item) ? 'outline-danger' : 'outline-primary'">{{ isSelected(item) ? 'Deselect' : $t('action.select') }}</b-button>
      </template>
    </b-table>
    <paginate v-if="items.length > 0" :queries="queries" :fetcher="getInvoices" :total="invoices.total" class="card-footer" />
  </b-modal>
</template>
<script>
import { formatDayEndUtc, formatDayStartUtc, formatLocalDateTime, setupDateRanges } from '@/utils/dateTimeUtils';
import { AWSSecurityWithURL } from '@/store/services/aws'
import { mapGetters } from 'vuex';

export default {
  name: 'InvoiceLookup',
  props: { selected: Array },
  data() {
    return {
      isLoading: false,
      queries: { id: null, value: null, product_id: null, start: null, end: null, page: 1, perPage: 10 },
      ranges: setupDateRanges(true),
      maxDate: formatLocalDateTime(null, 'YYYY-MM-DD', true),
      fields: [
        { key: 'id', label: 'ID' },
        { key: 'src', label: 'Preview',
          formatter: (value, key, item) => {
            if (item.uploaded && value && value.includes(process.env.VUE_APP_AWS_S3_SECURITY_BUCKET_NAME) && !item.loaded) {
              item.loaded = true // prevent image loaded repeatedly
              AWSSecurityWithURL({ name: item.name }, 'rkings').then(({ blob }) => (this.$set(item, 'src_security', URL.createObjectURL(blob))))
            }
            if (!item.uploaded) {
              return value
            }
          }, },
        { key: 'name', label: 'Formatted Name', tdAttr: { style: 'cursor: pointer; white-space: normal' } },
        { key: 'created_at', label: 'Created At', formatter: (v) => formatLocalDateTime(v), sortable: true, tdAttr: { style: 'cursor: pointer' } },
        { key: 'action', label: 'Action' },
      ],
    }
  },
  computed: {
    ...mapGetters({ invoices: 'request/invoices' }),
    dateRange: {
      get() {
        return {
          startDate: formatLocalDateTime(this.queries.start, 1, false, true),
          endDate: formatLocalDateTime(this.queries.end, 1, false, true),
        }
      },
      set(newVal) {
        this.queries['start'] = formatDayStartUtc(newVal.startDate, 0, false, true)
        this.queries['end'] = formatDayEndUtc(newVal.endDate, 0, false, true)
        return newVal
      },
    },
    items() {
      const tmp =
        this.invoices?.data.map((x) => ({
          id: x.id,
          status: x.status,
          src: x.value,
          position: 0,
          size: x.size,
          uploaded: true,
          file: null,
          name: x.value.slice(x.value.lastIndexOf('/') + 1),
          created_at: x.created_at,
        })) ?? []
      return tmp
    },
  },
  methods: {
    getInvoices() {
      this.isLoading = true
      this.$store
        .dispatch('request/getInvoices', { ...this.queries, errorMsg: 'Failed to get invoices' })
        .then(() => (this.isLoading = false))
        .catch(() => (this.isLoading = false))
    },
    onSearch(evt) {
      this.queries.page = 1
      this.getInvoices()
    },
    onReset(evt) {
      this.queries.id = null
      this.queries.value = null
      this.queries.product_id = null
      this.queries.start = null
      this.queries.end = null
      this.queries.page = 1
      this.getInvoices()
    },
    onShow(evt) {
      this.getInvoices()
    },
    onSelect(item) {
      if (this.isSelected(item)) {
        return this.selected.splice(
          this.selected.findIndex((x) => x.src == item.src),
          1
        ) // remove
      } else {
        return this.selected.splice(0, 0, { ...item, id: null }) // add
      }
    },
    rowClicked(item, index, evt) {
      if (evt.target.cellIndex > 0) {
        this.onSelect(item)
      }
    },
    isSelected(item) {
      if (this.selected.length > 0) {
        return this.selected.findIndex((x) => x.src == item.src) > -1
      } else {
        return false
      }
    },
  },
}
</script>
<style>
.invoice-lookup-modal {
  width: max-content;
  min-width: 50vw;
  max-width: 75vw;
}
</style>
