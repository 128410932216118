var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticStyle:{"display":"contents"}},[_c('loading',{attrs:{"active":_vm.isLoading,"can-cancel":false,"is-full-page":false}}),(_vm.details)?_c('b-row',{attrs:{"cols":"1"}},[_c('b-col',[_c('div',{staticClass:"mt-3 d-flex"},[_c('span',{staticClass:"font-weight-normal text-sm",staticStyle:{"width":"7rem"}},[_vm._v(_vm._s(_vm.$t('general.name')))]),_c('span',{staticClass:"ml-2 font-weight-light"},[_vm._v(_vm._s(_vm.details.name))]),(_vm.details.nameLang)?_c('icon-translate',{attrs:{"onClick":function () { return _vm.pushProductEdit(_vm.details); }}}):_vm._e(),_c('bool-badge',{staticClass:"ml-2",attrs:{"value":_vm.details.status,"text":_vm.$t('product.stock_status_list')[_vm.details.status]}})],1)])],1):_vm._e(),(_vm.details)?_c('b-row',{attrs:{"cols":"1","cols-xl":"2"}},[_c('b-col',{staticClass:"pr-xl-4"},[_vm._l(([
          { key: 'type', label: _vm.$t('general.type'), value: _vm.$t(("product.types." + (_vm.details.type ? _vm.details.type.replace('-', '_') : ''))) },
          _vm.simplified ? {} : { key: 'store', label: _vm.$tc('general.store', 1), value: null },
          { key: 'cost', label: _vm.$t('general.cost'), value: null },
          { key: 'initial_amount', label: _vm.$t('product.initial_amount'), value: null },
          { key: 'stock_amount', label: _vm.$t('product.stock_amount'), value: null },
          { key: 'available_amount', label: _vm.$t('product.available_amount'), value: null },
          { key: 'storing_time', label: _vm.$t('product.storing_time'), value: null },
          { key: 'delivering_time', label: _vm.$t('product.delivering_time'), value: null },
          { key: 'created_at', label: _vm.$t('general.created_at'), value: null },
          { key: 'updated_at', label: _vm.$t('general.updated_at'), value: null },
          { key: 'updated_by', label: _vm.$t('general.updated_by'), value: null } ]),function(ref){
        var key = ref.key;
        var label = ref.label;
        var value = ref.value;
return _c('div',{key:'product-details-' + key,staticClass:"mt-3 d-flex"},[_c('span',{staticClass:"font-weight-normal text-sm",staticStyle:{"width":"7rem"}},[_vm._v(_vm._s(label))]),_c('span',{staticClass:"ml-2 font-weight-light"},[_vm._v(_vm._s(value ? value : _vm.details[key] >= 0 || _vm.details[key] ? _vm.details[key] : _vm.$t('no_data')))])])}),_c('div',{staticClass:"mt-3"},[_c('div',{staticClass:"d-flex flex-row flex-nowrap align-items-center font-weight-normal text-sm"},[_vm._v(" "+_vm._s(_vm.$t('general.description'))+" "),(_vm.details.descrLang)?_c('icon-translate',{attrs:{"onClick":function () { return _vm.pushProductEdit(_vm.details); }}}):_vm._e()],1),_c('b-form-textarea',{staticClass:"mt-3 mb-0",staticStyle:{"font-size":"0.85rem"},attrs:{"id":"detail-description","disabled":"","size":"lg","rows":"12","no-resize":""},model:{value:(_vm.details.description),callback:function ($$v) {_vm.$set(_vm.details, "description", $$v)},expression:"details.description"}})],1)],2),_c('b-col',{staticClass:"pl-xl-4"},[_c('p',{staticClass:"mt-3 mb-0 font-weight-normal text-sm"},[_vm._v(_vm._s(_vm.$tc('general.image', _vm.images.length)))]),_c('b-table',{staticClass:"mx-0 mt-3 mb-0 p-0 flex-grow-1",attrs:{"show-empty":"","empty-text":_vm.$t('notify.table_no_records'),"small":"","hover":"","striped":"","responsive":"","sticky-header":"20rem","head-variant":"light","tbody-class":"h-100","fields":_vm.fields,"items":_vm.images},scopedSlots:_vm._u([{key:"cell(status)",fn:function(ref){
        var value = ref.value;
return [_c('bool-badge',{attrs:{"value":value,"text":value == 1 ? _vm.$t('status.shown') : _vm.$t('status.hidden')}})]}},{key:"cell(value)",fn:function(ref){
        var item = ref.item;
return [_c('a',{attrs:{"href":("" + (item.value)),"target":"_blank"}},[_c('b-img-lazy',{staticClass:"hover-image",staticStyle:{"max-height":"4rem"},attrs:{"src":item.value,"fluid":"","block":"","rounded":""}})],1)]}}],null,false,4267617329)}),_c('p',{staticClass:"mt-3 mb-0 font-weight-normal text-sm"},[_vm._v(_vm._s(_vm.$tc('product.invoice', _vm.invoices.length)))]),_c('b-table',{staticClass:"mx-0 mt-3 mb-0 p-0 flex-grow-1",attrs:{"show-empty":"","empty-text":_vm.$t('notify.table_no_records'),"small":"","hover":"","striped":"","responsive":"","sticky-header":"11rem","head-variant":"light","tbody-class":"h-100","fields":_vm.invoiceFields,"items":_vm.invoices},scopedSlots:_vm._u([{key:"cell(status)",fn:function(ref){
        var value = ref.value;
return [_c('bool-badge',{attrs:{"value":value,"text":value == 1 ? _vm.$t('status.shown') : _vm.$t('status.hidden')}})]}},{key:"cell(value)",fn:function(ref){
        var value = ref.value;
        var item = ref.item;
return [_c('a',{attrs:{"href":item.value.indexOf('security') > -1 ? item.src_security : item.value,"target":"_blank","type":_vm.isPdf(value) ? 'application/pdf' : 'image/jpeg'}},[(_vm.isPdf(value))?_c('i',{staticClass:"fa fa-file-pdf text-xs font-weight-light text-wrap"},[_vm._v(" "+_vm._s(value.slice(value.lastIndexOf('/') + 1, value.indexOf('.pdf')))+" ")]):(item.value.indexOf('security') < 0)?_c('b-img-lazy',{staticClass:"hover-image",staticStyle:{"max-height":"4rem"},attrs:{"src":item.value,"fluid":"","block":"","rounded":""}}):(item.src_security)?_c('b-img-lazy',{staticClass:"hover-image",staticStyle:{"max-height":"4rem"},attrs:{"src":item.src_security,"fluid":"","block":"","rounded":""}}):_vm._e()],1)]}}],null,false,375324927)})],1)],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }