<template>
  <div class="rounded bg-white p-4" style="margin-left: -16px; margin-right: -16px">
    <div class="w-100 d-flex flex-row align-items-center">
      <label class="font-weight-normal m-0">Live Draw Prize Settings<span class="text-danger ml-1">*</span></label>
      <b-button variant="outline-primary" class="ml-3 my-0" v-b-modal.product-lookup v-if="!noEditByStatus && !noEditMultiStore">
        {{ $t('product.product_lookup') }}
      </b-button>
      <div class="ml-auto">
        <span class="font-weight-normal text-success">{{ $t('general.total') }}: {{ allTotalCost }}</span>
      </div>
    </div>
    <b-table
      show-empty
      small
      hover
      responsive
      sticky-header="240px"
      head-variant="light"
      class="mt-3 mb-0 mx-0 p-0"
      tbody-class="h-100"
      :empty-text="`⚠ ${$t('product.at_least_one')}`"
      :fields="rewardFields"
      :items="liveDrawRewards">
      <template #head()="{ label, field }">{{ label }}<b-icon-question-circle-fill v-if="field.tooltip" class="ml-1" v-b-tooltip.hover.topright="field.tooltip" /></template>
      <template #cell(name)="{ value, item }">
        <div v-if="item.nameLang" class="w-auto d-flex flex-row flex-nowrap align-items-center">
          <span class="action" @click="onViewDetails(item)" v-b-tooltip.hover.right="value">{{ value }}</span>
          <icon-translate :onClick="() => pushProductEdit(item)" classes="pl-1" />
        </div>
        <span v-else class="action" @click="onViewDetails(item)" v-b-tooltip.hover.right="value">{{ value }}</span>
      </template>
      <template #cell(store_id)="{ value }"><store-badge :store_id="value" /></template>
      <template #cell(amount)="{ index, item }" v-if="!noEditByStatus">
        <b-form-input
          :id="'hold-amount-' + index"
          :key="'hold-amount-' + index"
          v-model="item.amount"
          number
          type="number"
          step="1"
          min="1"
          :max="maxToBeHold(item)"
          :state="holdAmtValid"
          style="width: 60px" />
      </template>
      <template #cell(action)="{ item }">
        <b-button variant="outline-danger" size="xs" @click="onRemove(item)" :disabled="noRemoveByStatus"><b-icon-trash /></b-button>
      </template>

      <template #cell(first_payment_date)="{ value }">
        <template v-if="value == 'no_data'">{{ $t('no_data') }}</template>
        <b-form-input v-else-if="value" :value="value" type="date" disabled style="width: 120px; padding-right: 0px" />
        <template v-else>{{ $t('product.first_payment_date') }}</template>
      </template>
      <template #cell(last_payment_date)="{ value }">
        <template v-if="value == 'no_data'">{{ $t('no_data') }}</template>
        <b-form-input v-else-if="value" :value="value" type="date" disabled style="width: 120px; padding-right: 0px" />
        <template v-else>{{ $t('product.last_payment_date') }}</template>
      </template>

      <template #bottom-row v-if="productSameStore">
        <td colspan="1" class="font-weight-bold text-left">{{ $t('order.subtotal') }}</td>
        <td colspan="2" class="font-weight-bold text-right text-success">{{ totalCostLocal }}</td>
        <td colspan="7"></td>
      </template>
    </b-table>
    <b-form-invalid-feedback :state="productSameStore">⚠ {{ $t('competition.same_store_notice') }}</b-form-invalid-feedback>

    <b-modal
      lazy
      centered
      id="product-lookup"
      :title="$t('product.product_lookup')"
      title-class="w-100 d-flex justify-content-center align-self-center"
      header-class="py-2"
      body-class="pt-0"
      dialog-class="product-lookup-modal"
      hide-footer
      @show="onShow"
      ><loading :active="isLoading" :can-cancel="false" :is-full-page="false" />
      <ProductSearchInput :queries="queries" :getList="getList" />
      <b-table
        :fields="lookupFields"
        :items="items"
        @row-clicked="rowClicked"
        show-empty
        :empty-text="$t('notify.table_no_records')"
        small
        hover
        responsive
        sticky-header="60vh"
        head-variant="light"
        class="m-0 p-0">
        <template #head()="{ label, field }">{{ label }}<b-icon-question-circle-fill v-if="field.tooltip" class="ml-1" v-b-tooltip.hover.topright="field.tooltip" /></template>
        <template #cell(name)="{ value, item }">
          <div v-if="item.nameLang" class="w-auto d-flex flex-row flex-nowrap align-items-center">
            <div class="action" @click="onViewDetails(item)" style="white-space: normal; min-width: 8rem">{{ value }}</div>
            <icon-translate :onClick="() => pushProductEdit(item)" classes="pl-1" />
          </div>
          <div v-else class="action" @click="onViewDetails(item)" style="white-space: normal; min-width: 12rem">{{ value }}</div>
        </template>
        <template #cell(store_id)="{ value }"><store-badge :store_id="value" /></template>
        <template #cell(action)="{ item }">
          <b-button v-if="isSelected(item.id)" variant="outline-danger" @click="onRemove(item)" :disabled="noRemoveByStatus">
            {{ $t('action.remove') }}
          </b-button>
          <b-button v-else variant="outline-primary" @click="onSelect(item)">{{ $t('action.select') }}</b-button>
        </template>
      </b-table>
      <paginate v-if="items.length > 0" :queries="queries" :fetcher="getList" :total="products.total" class="card-footer" />
    </b-modal>
  </div>
</template>
<script>
import ProductSearchInput from '@/components/Product/ProductSearchInput.vue'
import productMixin from '@/mixins/product-mixin'
import routerMixin from '@/mixins/router-mixin'
import { getRandomStr, noTransFormatter } from '@/utils/index'
import { floatAddition, floatMultiplication, formatWithCurrency } from '@/utils/numberUtils'
import { mapGetters } from 'vuex'

export default {
  name: 'ProductLookup',
  mixins: [
    routerMixin,
    productMixin, // getExistingMultiPay
  ],
  components: { ProductSearchInput },
  props: {
    form: Object,
    isDrawingInstantWin: Boolean,
    noEditAfterPublish: Boolean,
    noEditMultiStore: Boolean,
    holdAmtValidation: Boolean || null,
    productStoreValid: Boolean || null,
    imageItems: Array,
    stores: Array,
    onViewDetails: Function,
  },
  data() {
    return {
      isLoading: false,
      queries: {
        name: '',
        // status: 1, // available, stock amount > 0
        store_id: this.form.store_id,
        store_ids: `[${this.form.store_id}]`,
        available: 1, // all in stock
        page: 1,
        perPage: 10,
      },
    }
  },
  computed: {
    ...mapGetters({ products: 'request/productList' }),
    liveDrawRewards() {
      return this.form?.rewards.filter((v) => v.reward_type == 'winner') ?? []
    },
    noEditByStatus() {
      return this.isDrawingInstantWin ? false : this.noEditAfterPublish
    },
    noRemoveByStatus() {
      return this.isDrawingInstantWin ? this.liveDrawRewards.length < 2 : this.noEditAfterPublish
    },
    items() {
      return this.products?.data ?? []
    },
    rewardFields() {
      const hide = this.noEditByStatus || this.noEditMultiStore
      return [
        { key: 'name', label: this.$t('product.product_name'), formatter: noTransFormatter, thStyle: 'width: 12rem', tdAttr: { style: 'white-space: normal; min-width: 200px' } },
        { key: 'store_id', label: this.$tc('general.store', 1), tooltip: this.$t('competition.same_store_notice') },
        { key: 'cost', label: this.$t('general.cost'), formatter: (v, k, i) => formatWithCurrency(v, i.currency), class: 'text-right' },
        {
          key: 'stock_amount',
          label: this.$t('product.amounts_label'),
          formatter: (v, k, i) => i.initial_amount + ' / ' + v + ' / ' + (this.noEditByStatus ? i.amount : i.available_amount - i.amount - this.instantWinProductAmount(i)),
          class: 'text-center',
          tooltip:
            this.$t('product.initial_amount') + ' / ' + this.$t('product.stock_amount') + ' / ' + (this.noEditByStatus ? this.$t('product.reserved_amount') : this.$t('product.available_amount')),
        },
        hide ? null : { key: 'amount', label: this.$t('product.reserved') },
        hide ? null : { key: 'action', label: this.$tc('general.action', 1) },
        { key: 'payment_frequency', formatter: (v, k, item) => this.getExistingMultiPay(item)?.code ?? 'One-off', tdClass: 'text-capitalize' },
        { key: 'payment_numbers', label: 'Payment Times', formatter: (v, k, item) => this.getExistingMultiPay(item)?.value ?? 1, tdClass: 'text-capitalize' },
        {
          key: 'first_payment_date',
          formatter: (v, k, item) => {
            let tmp = null
            const multiPay = this.getExistingMultiPay(item)
            if (multiPay?.code && multiPay?.value) {
              tmp = this.firstPayDate
            } else {
              tmp = 'no_data'
            }
            return tmp
          },
        },
        {
          key: 'last_payment_date',
          formatter: (v, k, item) => {
            let tmp = null
            const multiPay = this.getExistingMultiPay(item)
            if (!multiPay?.code || !multiPay?.value) {
              tmp = 'no_data'
            } else if (this.form.draw_date) {
              tmp = this.$moment(this.form.draw_date).add(Number(multiPay.value), multiPay.code.replaceAll('ly', '')).format('YYYY-MM-DD')
            }
            return tmp
          },
        },
      ]
    },
    lookupFields() {
      return [
        { key: 'name', label: this.$t('product.product_name'), formatter: noTransFormatter, thStyle: 'width: 12rem' },
        { key: 'type', label: this.$t('general.type'), formatter: (v) => this.$t(`product.types.${v.replace('-', '_')}`), tdAttr: { style: 'cursor: pointer' }, tdClass: 'text-capitalize' },
        { key: 'store_id', label: this.$tc('general.store', 1), tdAttr: { style: 'cursor: pointer' } },
        { key: 'cost', label: this.$t('general.cost'), formatter: (v, k, i) => formatWithCurrency(v, i.currency), class: 'text-right', tdAttr: { style: 'cursor: pointer' } },
        {
          key: 'stock_amount',
          label: this.$t('product.amounts_label'),
          formatter: (v, k, i) => i.initial_amount + ' / ' + v + ' / ' + (+i.available_amount - this.instantWinProductAmount(i)),
          tdAttr: { style: 'cursor: pointer' },
          class: 'text-center',
          tooltip: this.$t('product.initial_amount') + ' / ' + this.$t('product.stock_amount') + ' / ' + this.$t('product.available_amount'),
        },
        { key: 'action', label: this.$tc('general.action', 1) },
      ]
    },
    isEditRoute() {
      return this.$route.path.indexOf('/competitions/competition-list/edit') > -1
    },
    productSameStore() {
      if (this.liveDrawRewards.length > 0) {
        const compareTarget = this.form.allow_multi_stores ? this.liveDrawRewards[0].store_id : this.form.store_id
        let tmp = true
        for (let i = 0; i < this.liveDrawRewards.length; i++) {
          tmp = tmp && this.liveDrawRewards[i].store_id === compareTarget
        }
        this.$emit('update:productStoreValid', tmp)
        return tmp
      } else {
        this.$emit('update:productStoreValid', null)
        return null
      }
    },
    holdAmtValid() {
      if (this.liveDrawRewards.length >= 1) {
        let tmp = true
        if (!this.noEditByStatus) {
          this.liveDrawRewards.forEach((x) => {
            tmp = tmp && x.amount > 0 && x.available_amount > 0 && x.amount <= x.available_amount
          })
        }
        this.$emit('update:holdAmtValidation', tmp)
        return tmp
      } else {
        this.$emit('update:holdAmtValidation', null)
        return null
      }
    },
    totalCostLocal() {
      if (this.liveDrawRewards.length > 0) {
        let tmp = 0
        this.liveDrawRewards.forEach((v) => (tmp = floatAddition(tmp, floatMultiplication(v.cost, v.amount))))
        return formatWithCurrency(tmp, this.liveDrawRewards[0].currency)
      } else {
        return null
      }
    },
    allTotalCost() {
      if (this.liveDrawRewards.length > 0) {
        const curr = this.liveDrawRewards[0].currency
        let all = 0
        let winner = 0
        let instantWins = 0
        this.liveDrawRewards.forEach((v) => (winner = floatAddition(winner, floatMultiplication(v.cost ?? 0, v.amount ?? 0))))
        if (this.form.instant_wins?.length > 0) {
          this.form.instant_wins.forEach((v) => {
            instantWins = floatAddition(instantWins, v.alternative_cash_amount ?? 0)
            instantWins = floatAddition(instantWins, v.alternative_credit_amount ?? 0)
            instantWins = floatAddition(instantWins, v.cost ?? 0)
          })
        }
        all = floatAddition(winner, instantWins)
        this.$set(this.form, 'total_cost', all)
        this.$emit('update:form.total_cost', all)
        let output = ''
        if (instantWins != 0) {
          output = formatWithCurrency(winner, curr) + ' + ' + formatWithCurrency(instantWins, curr) + ' = ' + formatWithCurrency(all, curr)
        } else {
          output = formatWithCurrency(all, curr)
        }
        return output
      } else {
        this.$set(this.form, 'total_cost', 0)
        this.$emit('update:form.total_cost', 0)
        return 0
      }
    },
    firstPayDate() {
      if (this.form.draw_date) {
        return this.$moment(this.form.draw_date).add(1, 'd').format('YYYY-MM-DD')
      } else {
        return null
      }
    },
  },
  watch: {
    'form.store_id'(newVal) {
      this.queries.store_id = newVal
    },
    'queries.store_id'(newVal, oldVal) {
      this.queries['store_ids'] = newVal ? `[${newVal}]` : null
    },
    productSameStore(newVal) {
      this.$emit('update:productStoreValid', newVal)
    },
    holdAmtValid(newVal) {
      this.$emit('update:holdAmtValidation', newVal)
    },
  },
  methods: {
    instantWinProductAmount(i) {
      return this.form.instant_wins?.reduce?.((acc, v) => {
        v.product && v.product_id == i.id ? (acc += +v.amount) : acc
      }, 0)
    },
    maxToBeHold(item) {
      const tmp = this.form.instant_wins.reduce((acc, v) => (v.product && v.product_id == item.id ? (acc += +v.amount) : acc), 0)
      return item.available_amount - tmp
    },
    onShow(evt) {
      if (!this.lastReq || this.lastReq.store_id !== this.form.store_id) {
        this.queries.store_id = this.form.store_id
      }
      this.getList()
    },
    getList(_params) {
      this.isLoading = true
      const params = { ..._params }
      for (const key in this.queries) {
        if (key !== 'store_id') {
          params[key] = this.queries[key]
        }
      }
      this.$store
        .dispatch('request/getProductList', params)
        .then(() => (this.isLoading = false))
        .catch(() => (this.isLoading = false))
    },
    onSearch() {
      this.queries.page = 1
      this.getList()
    },
    onReset() {
      this.queries.name = ''
      this.onSearch()
    },
    rowClicked(item, index, evt) {
      if (evt.target.cellIndex > 0) {
        if (!this.isSelected(item.id)) {
          this.onSelect(item)
        } else if (!this.noRemoveByStatus) {
          this.onRemove(item)
        }
      }
    },
    isSelected(id) {
      return !!this.form.rewards.find((x) => x.id === id)
    },
    onSelect(obj) {
      if (!this.isUnavailable(obj)) {
        if (obj.id != this.form.rewards[0]?.id) {
          const data = this.form.rewards[0] ?? obj
          this.form['title'] = data.name
          this.form['description'] = data.description
          this.stores.forEach((x, i) => {
            this.stores[i]['title'] = data.name
            this.stores[i]['description'] = data.description
          })
          if (!this.isEditRoute) {
            const preFix = data.name.match(/\s*(\w+\s*\w+)/)?.[0]?.replace(' ', '-')
            this.form.short_name = preFix ? (preFix + (this.stores[0].end_time ? '-' + this.$moment.utc(this.stores[0].end_time).format('YYYY-MMM') + '-' + getRandomStr(2) : '')).toLowerCase() : ''
          }
        }
        const tmp = obj.attributes.filter((x) => x.code == 'image') // get product images
        tmp.sort((a, b) => a.position - b.position)
        tmp.forEach((x) => {
          const img = {
            product_id: obj.id,
            id: null,
            status: x.status,
            src: x.value,
            position: this.imageItems.length + 1,
            size: NaN,
            uploaded: true,
            file: null,
            name: x.value.substring(x.value.lastIndexOf('/') + 1),
            store_id: obj.store_id,
          }
          this.imageItems.push(img)
        })
        this.form.rewards.push({ ...obj, reward_type: 'winner', amount: 1 })
      }
    },
    isUnavailable(item) {
      const liveDrawReward = this.liveDrawRewards.find((v) => v.id == item.id)
      const instantWinReward = this.form.instant_wins.filter((v) => v.product && v.product_id == item.id)
      let tmp = 0
      if (instantWinReward.length > 0) {
        instantWinReward.forEach((v) => (tmp += +v.amount))
      }
      return liveDrawReward ? item.available_amount - liveDrawReward.amount - tmp < 1 : item.available_amount - tmp < 1
    },
    onRemove(obj) {
      this.form.rewards = this.form.rewards.filter((x) => x.id !== obj.id)
      this.form['title'] = this.form.rewards[0]?.name
      this.form['description'] = this.form.rewards[0]?.description
      this.stores.forEach((x, i) => {
        this.stores[i]['title'] = this.form.rewards[0]?.name
        this.stores[i]['description'] = this.form.rewards[0]?.description
      })
      if (!this.isEditRoute) {
        const preFix = this.form.rewards[0]?.name.match(/\s*(\w+\s*\w+)/)?.[0]?.replace(' ', '-')
        this.form.short_name = preFix ? (preFix + (this.stores[0].end_time ? '-' + this.$moment.utc(this.stores[0].end_time).format('YYYY-MMM') + '-' + getRandomStr(2) : '')).toLowerCase() : ''
      }
      const len = this.imageItems.length
      for (let i = 0; i < len; i++) {
        const toRemove = this.imageItems.findIndex((x) => x.product_id && x.product_id == obj.id)
        if (toRemove > -1) {
          this.imageItems.splice(toRemove, 1)
        }
      }
      this.imageItems.forEach((x, i) => (x.position = i + 1))
    },
    formatWithCurrency,
  },
}
</script>
<style lang="scss" scoped>
.product-details-modal {
  width: max-content !important;
  min-width: 56rem !important;
  max-width: 90vw !important;
}
@media screen and (max-width: 1024px) {
  .modal-dialog {
    max-width: 80vw;
    margin-left: 10vw;
  }
  .modal-content {
    max-height: 80vh;
  }
}
</style>
