<template>
  <div style="display: contents">
    <loading :active="isLoading" :can-cancel="false" :is-full-page="false" />
    <b-row cols="1" v-if="details">
      <b-col>
        <div class="mt-3 d-flex">
          <span class="font-weight-normal text-sm" style="width: 7rem">{{ $t('general.name') }}</span>
          <span class="ml-2 font-weight-light">{{ details.name }}</span>
          <icon-translate v-if="details.nameLang" :onClick="() => pushProductEdit(details)" />
          <bool-badge :value="details.status" :text="$t('product.stock_status_list')[details.status]" class="ml-2" />
        </div>
      </b-col>
    </b-row>
    <b-row cols="1" cols-xl="2" v-if="details">
      <b-col class="pr-xl-4">
        <div
          class="mt-3 d-flex"
          v-for="{ key, label, value } in [
            { key: 'type', label: $t('general.type'), value: $t(`product.types.${details.type ? details.type.replace('-', '_') : ''}`) },
            simplified ? {} : { key: 'store', label: $tc('general.store', 1), value: null },
            { key: 'cost', label: $t('general.cost'), value: null },
            { key: 'initial_amount', label: $t('product.initial_amount'), value: null },
            { key: 'stock_amount', label: $t('product.stock_amount'), value: null },
            { key: 'available_amount', label: $t('product.available_amount'), value: null },
            { key: 'storing_time', label: $t('product.storing_time'), value: null },
            { key: 'delivering_time', label: $t('product.delivering_time'), value: null },
            { key: 'created_at', label: $t('general.created_at'), value: null },
            { key: 'updated_at', label: $t('general.updated_at'), value: null },
            { key: 'updated_by', label: $t('general.updated_by'), value: null },
          ]"
          :key="'product-details-' + key">
          <span class="font-weight-normal text-sm" style="width: 7rem">{{ label }}</span>
          <span class="ml-2 font-weight-light">{{ value ? value : details[key] >= 0 || details[key] ? details[key] : $t('no_data') }}</span>
        </div>
        <div class="mt-3">
          <div class="d-flex flex-row flex-nowrap align-items-center font-weight-normal text-sm">
            {{ $t('general.description') }}
            <icon-translate v-if="details.descrLang" :onClick="() => pushProductEdit(details)" />
          </div>
          <b-form-textarea id="detail-description" v-model="details.description" disabled size="lg" rows="12" no-resize style="font-size: 0.85rem" class="mt-3 mb-0" />
        </div>
      </b-col>
      <b-col class="pl-xl-4">
        <p class="mt-3 mb-0 font-weight-normal text-sm">{{ $tc('general.image', images.length) }}</p>
        <b-table
          show-empty
          :empty-text="$t('notify.table_no_records')"
          small
          hover
          striped
          responsive
          sticky-header="20rem"
          head-variant="light"
          class="mx-0 mt-3 mb-0 p-0 flex-grow-1"
          tbody-class="h-100"
          :fields="fields"
          :items="images">
          <template #cell(status)="{ value }">
            <bool-badge :value="value" :text="value == 1 ? $t('status.shown') : $t('status.hidden')" />
          </template>
          <template #cell(value)="{ item }">
            <a :href="`${item.value}`" target="_blank">
              <b-img-lazy :src="item.value" fluid block rounded style="max-height: 4rem" class="hover-image" />
            </a>
          </template>
        </b-table>
        <p class="mt-3 mb-0 font-weight-normal text-sm">{{ $tc('product.invoice', invoices.length) }}</p>
        <b-table
          show-empty
          :empty-text="$t('notify.table_no_records')"
          small
          hover
          striped
          responsive
          sticky-header="11rem"
          head-variant="light"
          class="mx-0 mt-3 mb-0 p-0 flex-grow-1"
          tbody-class="h-100"
          :fields="invoiceFields"
          :items="invoices">
          <template #cell(status)="{ value }">
            <bool-badge :value="value" :text="value == 1 ? $t('status.shown') : $t('status.hidden')" />
          </template>
          <!-- TODO: TODO: read security aws url for product invoice -->
          <template #cell(value)="{ value, item }">
            <a :href="item.value.indexOf('security') > -1 ? item.src_security : item.value" target="_blank" :type="isPdf(value) ? 'application/pdf' : 'image/jpeg'">
              <i v-if="isPdf(value)" class="fa fa-file-pdf text-xs font-weight-light text-wrap">
                {{ value.slice(value.lastIndexOf('/') + 1, value.indexOf('.pdf')) }}
              </i>
              <b-img-lazy v-else-if="item.value.indexOf('security') < 0" :src="item.value" fluid block rounded style="max-height: 4rem" class="hover-image" />
              <b-img-lazy v-else-if="item.src_security" :src="item.src_security" fluid block rounded style="max-height: 4rem" class="hover-image" />
            </a>
          </template>
        </b-table>
      </b-col>
    </b-row>
  </div>
</template>
<script>
import routerMixin from '@/mixins/router-mixin'
import { AWSSecurityWithURL } from '@/store/services/aws'
import { formatLocalDateTime } from '@/utils/dateTimeUtils'
import { noTransFormatter } from '@/utils/index'
import { formatWithCurrency } from '@/utils/numberUtils'
import { mapGetters } from 'vuex'
export default {
  name: 'ProductDetails',
  mixins: [routerMixin],
  props: { product: Object },
  data() {
    return {
      isLoading: false,
      details: null,
    }
  },
  computed: {
    fields() {
      return [
        { key: 'id', label: this.$t('general.id') },
        { key: 'status', label: this.$t('general.status') },
        { key: 'value', label: this.$t('general.preview'),
          formatter: (value, key, item) => {
            if (!item.loaded && value.includes(process.env.VUE_APP_AWS_S3_SECURITY_BUCKET_NAME)) {
              item.loaded = true
              AWSSecurityWithURL({ name: item.value.slice(value.lastIndexOf('/') + 1) }, 'rkings').then(({ blob }) => (this.$set(item, 'src_security', URL.createObjectURL(blob))))
            }
            if (!item.uploaded) {
              return value
            }
          }, },
        { key: 'position', label: this.$t('general.position') },
      ]
    },
    invoiceFields() {
      return this.fields.filter((x) => x.key !== 'position')
    },
    ...mapGetters({ productDetails: 'request/productDetails' }),
    images() {
      return this.details.attributes?.filter((x) => x.code === 'image').sort((a, b) => a.position - b.position) ?? []
    },
    invoices() {
      return this.details.attributes?.filter((x) => x.code === 'invoice') ?? []
    },
  },
  methods: {
    isPdf(url) {
      return url.slice(url.lastIndexOf('.') + 1) === 'pdf'
    },
    initDetails(obj) {
      obj = obj ?? this.product
      this.details = {
        ...obj,
        store: [this.$t('uk'), this.$t('address.mexico')][obj.store_id - 1],
        cost: formatWithCurrency(obj.cost, obj.currency),
        description: obj.description?.replace(/<[^>]+>/g, '') ?? '',
        storing_time: formatLocalDateTime(obj.storing_time),
        delivering_time: formatLocalDateTime(obj.delivering_time),
        created_at: formatLocalDateTime(obj.created_at),
        updated_at: formatLocalDateTime(obj.updated_at),
        updated_by: obj.admin?.name ?? obj.admin_user_id,
      }
      this.details['name'] = noTransFormatter(this.details['name'], 'name', this.details)
      this.details['description'] = noTransFormatter(this.details['description'], 'descr', this.details)
    },
  },
  mounted() {
    if (!this.product.attributes) {
      this.isLoading = true
      this.$store
        .dispatch('request/getProductDetails', { id: this.product.id })
        .then(() => {
          this.isLoading = false
          this.initDetails(this.productDetails)
        })
        .catch(() => (this.isLoading = false))
    } else {
      this.initDetails()
    }
  },
}
</script>
